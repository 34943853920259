.appear-box {
    animation: appear 0.7s;
}

@keyframes appear {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}